<script>
import appConfig from "@/app.config";
import { getAll as getConfig } from "@/services/configuracao.service"
import { restaurarSenha } from "@/services/usuario.service"
import { trataSucesso } from "@/utils/tratar.retornos"; //trataErro
import { messageError } from '@/utils/messages'

export default {
  page: {
    title: "Restaurar senha",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      appConfig,
      devConfig: null,
      email: "",
    };
  },
  mounted() {
    getConfig().then(response => {
      this.devConfig = response;
    });
  },
  methods: {
    async restaurar() {
      var isValid = await this.$validator.validateAll();
      if (isValid) {
        restaurarSenha(this.email)
          .then(response => {
            if (response) {
              trataSucesso(this, 'Você receberá um email com as informações de recuperação de senha.', 'home')
            } else {
              messageError(this, 'A solicitação de recuperação de senha não pôde concluída.');
            }
          })
          .catch(() => {
            messageError(this, 'Falha na solicitação de recuperação de senha.');
            //trataErro(err, this);
          })
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <router-link to="/" class="text-dark">
        <i class="mdi mdi-home-variant h2"></i>
      </router-link>
    </div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div>
              <router-link to="/" class="mb-5 d-block auth-logo">
                <img
                  src="@/assets/images/logo-dark.png"
                  alt
                  width="180"
                  class="logo logo-dark"
                />
                <img
                  src="@/assets/images/logo-light.png"
                  alt
                  width="180"
                  class="logo logo-light"
                />
              </router-link>
              <div class="card">
                <div class="card-body p-4">
                  <div class="text-center mt-2">
                    <h5 class="text-primary">Restaurar senha</h5>
                  </div>
                  <div class="p-2 mt-4">
                    <div class="text-muted mb-4" role="alert">
                      Preencha abaixo o seu email para receber os procedimentos para restauração da senha.
                    </div>
                    <form @submit.prevent="restaurar">
                      <f-input 
                        name="codigo" 
                        type="email"
                        v-model="email" 
                        v-validate="{required: true, email: true}" 
                        data-vv-as="E-mail"
                        label="Email"
                        :modelErrors="errors"
                        :cols="12"
                      />
                      <div class="row mb-0">
                        <div class="col-12 text-end">
                          <button class="btn btn-primary w-sm" type="submit">
                            Iniciar restauração
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->

              <div class="mt-5 text-center">
                <p>{{ appConfig.devYear }} ® {{ appConfig.title }} {{ devConfig ? ` - Versão ${devConfig.versao}` : "" }}</p>
                <p v-if="devConfig">Desenvolvido por <a :href="devConfig.devUrl" target="_blank">{{ devConfig.devNome }}</a></p>
              </div>
            </div>
            <!-- end col -->
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<style lang="scss" module></style>
